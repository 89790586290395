import React from "react";
import "../css/PrivacyPolicy.css";
import Header from "../components/Header"; // Import your Header component
import Footer from "../components/Footer"; // Import your Footer component

const PrivacyPolicy = () => {
    return (
        <div>
            {/* Header Component */}
            <Header />

            {/* Privacy Policy Content */}
            <div className="content">
                <h4>Last updated November 18, 2024</h4>
                <p>
                    Build America Center ("we" or "us" or "our") respects the privacy of our users ("user" or
                    "you"). This Privacy Policy explains how we collect, use, disclose, and safeguard your
                    information when you visit our mobile application (the "Application"). Please read this Privacy
                    Policy carefully. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT
                    ACCESS THE APPLICATION. We reserve the right to make changes to this Privacy Policy at any time
                    and for any reason. We will alert you about any changes by updating the “Last updated” date of
                    this Privacy Policy. You are encouraged to periodically review this Privacy Policy to stay
                    informed of updates. You will be deemed to have been made aware of, will be subject to, and will
                    be deemed to have accepted the changes in any revised Privacy Policy by your continued use of
                    the Application after the date such revised Privacy Policy is posted.
                </p>
                <p>
                    This Privacy Policy does not apply to the third-party online/mobile store from which you install
                    the Application or make payments, including any in-game virtual items, which may also collect
                    and use data about you. We are not responsible for any of the data collected by any such third
                    party.
                </p>
                <h4>COLLECTION OF YOUR INFORMATION</h4>
                <p>
                    We may collect information about you in a variety of ways. The information we may collect via the
                    Application depends on the content and materials you use, and includes:
                    <ul>
                        <li>Your Email ID would be used for account registration.</li>
                        <li>
                            Geo-Location Information: We may request access or permission to and track location-based
                            information from your mobile device, either continuously or while you are using the
                            Application, to provide location-based services. If you wish to change our access or
                            permissions, you may do so in your device's settings.
                        </li>
                        <li>
                            Mobile Device Access: We may request access or permission to certain features from your mobile
                            device, including your mobile device's storage, and other features. If you wish to change our
                            access or permissions, you may do so in your device's settings.
                        </li>
                        <li>
                            Push Notifications: We may request to send you push notifications regarding your account or
                            the Application. If you wish to opt-out from receiving these types of communications, you may
                            turn them off in your device's settings.
                        </li>
                        <li>
                            Data from Contests, Giveaways, and Surveys: Personal and other information you may provide
                            when entering contests or giveaways and/or responding to surveys.
                        </li>
                    </ul>
                </p>
                <h4>USE OF YOUR INFORMATION</h4>
                <p>
                    Having accurate information about you permits us to provide you with a smooth, efficient, and
                    customized experience. Specifically, we may use information collected about you via the
                    Application to:
                    <ul>
                        <li>Create and manage your account.</li>
                        <li>Request feedback and contact you about your use of the Application.</li>
                    </ul>
                </p>
                <h4>DISCLOSURE OF YOUR INFORMATION</h4>
                <p>
                    We may share information we have collected about you in certain situations. Your information may
                    be disclosed as follows:
                </p>
                <ul>
                    <li>
                        By Law or to Protect Rights: If we believe the release of information about you is necessary to
                        respond to legal processes, to investigate or remedy potential violations of our policies, or
                        to protect the rights, property, and safety of others, we may share your information as
                        permitted or required by any applicable law, rule, or regulation. This includes exchanging
                        information with other entities for fraud protection and credit risk reduction.
                    </li>
                    <li>
                        Online Postings: When you post comments, contributions, or other content to the Application,
                        your posts may be viewed by all users and may be publicly distributed outside the Application in
                        perpetuity.
                    </li>
                    <li>
                        Affiliates: We may share your information with our affiliates, in which case we will require
                        those affiliates to honor this Privacy Policy. Affiliates include our parent company and any
                        subsidiaries, joint venture partners, or other companies that we control or that are under
                        common control with us.
                    </li>
                    <li>
                        Business Partners: We may share your information with our business partners to offer you
                        certain products, services, or promotions.
                    </li>
                    <li>
                        Other Third Parties: We may share your information with advertisers and investors for the
                        purpose of conducting general business analysis. We may also share your information with such
                        third parties for marketing purposes, as permitted by law.
                    </li>
                    <li>
                        Sale or Bankruptcy: If we reorganize or sell all or a portion of our assets, undergo a merger,
                        or are acquired by another entity, we may transfer your information to the successor entity. If
                        we go out of business or enter bankruptcy, your information would be an asset transferred or
                        acquired by a third party. You acknowledge that such transfers may occur and that the transferee
                        may decline honor commitments we made in this Privacy Policy.
                    </li>
                </ul>
                <h4>THIRD-PARTY WEBSITES</h4>
                <p>
                    The Application may contain links to third-party websites and applications of interest, including
                    advertisements and external services, that are not affiliated with us. Once you have used these
                    links to leave the Application, any information you provide to these third parties is not covered
                    by this Privacy Policy, and we cannot guarantee the safety and privacy of your information.
                </p>
                <h4>CONTACT US</h4>
                <p>
                    If you have questions or comments about this Privacy Policy, please contact us at:
                    <br />
                    Build America Center
                    <br />
                    UMD, College Park,
                    <br />
                    MD 20740
                    <br />
                    <a href="mailto:bac@umd.edu">bac@umd.edu</a>
                </p>
            </div>

            {/* Footer Component */}

        </div>
    );
};

export default PrivacyPolicy;
