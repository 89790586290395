import React from "react";
import "../css/Home.css";
import homeImg from "../img/EC.png"; // Import the home image
import busImg from "../img/BUS.png"; // Import the bus image
import Footer from "../components/Footer"; // Import the Footer component

const Home = () => {
    return (
        <div className="home-container">
            <main className="main-content">
                <img src={homeImg} alt="Equity Rides Logo" className="logo" />
                <h1 className="main-title">Equity Rides</h1>
                <p className="main-description">
                    Using the EquityRides app allows the user to earn equity credits and
                    carbon credits by taking transit trips.
                </p>
                <section className="credits-section">
                    <div className="credits-text">
                        <article className="credit">
                            <h2 className="credit-title">Carbon Credits</h2>
                            <p className="credit-description">
                                Transport Carbon Credits (TCC) reward individuals or
                                organizations for using low-emission transportation options like
                                public transit, biking, and walking, incentivizing sustainable
                                travel to reduce greenhouse gas emissions and combat climate
                                change.
                            </p>
                        </article>
                        <article className="credit">
                            <h2 className="credit-title">Equity Credits</h2>
                            <p className="credit-description">
                                Transit Equity Credits (TEC) are financial incentives or rewards
                                designed to enhance equitable transit improvements and
                                development opportunities for individuals with limited
                                resources, promoting fair access to efficient and affordable
                                transportation options.
                            </p>
                        </article>
                    </div>
                    <div className="credits-image">
                        <img
                            src={busImg}
                            alt="Equity Rides Illustration"
                            className="side-image"
                        />
                    </div>
                </section>
            </main>

            {/* Footer Component */}
            <Footer />
        </div>
    );
};

export default Home;
