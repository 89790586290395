import React from "react";
import { Link } from "react-router-dom"; // Use React Router for navigation
import "./HeaderStyle.css"; // Import the CSS file
import navLogo from "../img/EC.png"; // Import the logo image

const Header = () => {
    return (
        <nav className="navbar">
            {/* Logo on the left */}
            <Link to="/" className="navbar-logo">
                <img className="navlogo" src={navLogo} alt="Equity Rides Logo" />
            </Link>

            {/* Centered Title */}
            <div className="navbar-title">Equity Rides</div>
        </nav>
    );
};

export default Header;
