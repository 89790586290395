import React, { useState } from "react";
import "../css/ResetPassword.css";
import WebsiteLogo from "../img/EC.png";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";

const ResetPassword = () => {
    const location = useLocation();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const searchParams = new URLSearchParams(location.search);
    const requestId = searchParams.get("id");
    const isValidPassword = (password) => {
        return password.length >= 8 && password.length <= 24 && /^[a-zA-Z0-9]+$/.test(password);
    };

    const resetPassword = async () => {
        setSubmitted(false);
        setErrorMessage("");

        if (!isValidPassword(password)) {
            setErrorMessage("Password must be 8–24 characters long and contain only letters and numbers.");
            return;
        }
        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match.");
            return;
        }

        try {
            const response = await fetch("https://test.incentrip.org:8080/equityride/reset-new-password ", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ requestId, password }),
            });

            if (response.ok) {
                setIsSuccessful(true);
            } else {
                const result = await response.json();
                setErrorMessage(result.errors || "Failed to reset password.");
                setIsSuccessful(false);
            }
        } catch (error) {
            setErrorMessage("An unexpected error occurred. Please try again.");
            console.error("Error during password reset:", error);
        } finally {
            setSubmitted(true);
        }
    };

    return (
        <div className="reset-container">
            <nav className="navbar bg-dark fixed-top reset-navbar"
                 style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <img src={WebsiteLogo} alt="Website Logo" style={{ height: '50px' }} />
                <p style={{
                    margin: 0,
                    flexGrow: 1,
                    textAlign: 'center',
                    color: 'white',
                    fontSize: '1.5rem',
                    fontWeight: 'bold'
                }}>
                    Reset Password
                </p>
            </nav>

            {!submitted || !isSuccessful ? (
                <div className="reset-password-frame">
                    <h3>Change Password</h3>
                    <p>
                        Your new password must be 8–24 characters long and contain only letters
                        and numbers.
                    </p>

                    <div className="input-group">
                        <span className="input-group-text">
                            <i className="fa fa-key"></i>
                        </span>
                        <input
                            type="password"
                            placeholder="New Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    <div className="input-group">
                        <span className="input-group-text">
                            <i className="fa fa-key"></i>
                        </span>
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>

                    {/* Error Messages */}
                    {errorMessage && (
                        <div className="error-messages">{errorMessage}</div>
                    )}

                    <button
                        type="button"
                        className={
                            password &&
                            confirmPassword &&
                            password === confirmPassword &&
                            isValidPassword(password)
                                ? "btn btn-success"
                                : "btn btn-secondary"
                        }
                        disabled={
                            !(
                                password &&
                                confirmPassword &&
                                password === confirmPassword &&
                                isValidPassword(password)
                            )
                        }
                        onClick={resetPassword}
                    >
                        Submit
                    </button>
                </div>
            ) : (
                <div className="message-div">
                    {isSuccessful ? (
                        <p className="text-success">Password has been reset successfully!</p>
                    ) : (
                        <p className="text-danger">{errorMessage || "Failed to reset your password. Try again."}</p>
                    )}
                </div>
            )}

            <Footer />
        </div>
    );
};

export default ResetPassword;
