import React from "react";
import "../css/Terms.css";
import Header from "../components/Header"; // Import your Header component
import Footer from "../components/Footer"; // Import your Footer component

const Terms = () => {
    return (
        <div>
            {/* Header Component */}
            <Header />

            {/* Terms Content */}
            <div className="content">
                <h3>1. Terms</h3>
                <p>
                    By accessing the Equity Rides application, you are agreeing to be bound by the Equity Rides
                    Terms and Conditions of Use, all applicable laws and regulations, and agree that you are
                    responsible for compliance with any applicable local laws. If you do not agree with any of
                    these terms, you are prohibited from using or accessing this site. The materials contained
                    in the EquityRide application are protected by applicable copyright and trademark law.
                </p>

                <h3>2. User License</h3>
                <ol type="a">
                    <li>
                        Permission is granted to temporarily download one copy of the materials (information or
                        software) on Build America Center's Equity Rides application for personal, non-commercial
                        transitory viewing only. This is the grant of a license, not a transfer of title, and
                        under this license you may not:
                        <ol type="i">
                            <li>modify or copy the materials;</li>
                            <li>
                                use the materials for any commercial purpose, or for any public display (commercial or
                                non-commercial);
                            </li>
                            <li>
                                attempt to decompile or reverse engineer any software contained on Build America
                                Center's Equity Rides application;
                            </li>
                            <li>
                                remove any copyright or other proprietary notations from the materials; or
                            </li>
                            <li>
                                transfer the materials to another person or "mirror" the materials on any other server.
                            </li>
                        </ol>
                    </li>
                    <li>
                        This license shall automatically terminate if you violate any of these conditions and may
                        be terminated by Build America Center at any time. Upon terminating your viewing of these
                        materials or upon the termination of this license, you must destroy any downloaded
                        materials in your possession whether in electronic or printed format.
                    </li>
                </ol>

                <h3>3. Disclaimer</h3>
                <ol type="a">
                    <li>
                        The materials on Build America Center's Equity Rides application are provided "as is". Build
                        America Center makes no warranties, expressed or implied, and hereby disclaims and negates
                        all other warranties, including without limitation, implied warranties or conditions of
                        merchantability, fitness for a particular purpose, or non-infringement of intellectual
                        property or other violation of rights. Further, Build America Center does not warrant or
                        make any representations concerning the accuracy, likely results, or reliability of the use
                        of the materials on Equity Rides application or otherwise relating to such materials or on
                        any sites linked to this site.
                    </li>
                </ol>

                <h3>4. Limitations</h3>
                <p>
                    In no event shall Build America Center or its suppliers be liable for any damages (including,
                    without limitation, damages for loss of data or profit, or due to business interruption,)
                    arising out of the use or inability to use the materials on Build America Center's Equity
                    Rides application, even if Build America Center or a Build America Center authorized
                    representative has been notified orally or in writing of the possibility of such damage.
                    Because some jurisdictions do not allow limitations on implied warranties, or limitations of
                    liability for consequential or incidental damages, these limitations may not apply to you.
                </p>

                <h3>5. Revisions and Errata</h3>
                <p>
                    The materials appearing on Build America Center's Equity Rides application could include
                    technical, typographical, or photographic errors. Build America Center does not warrant that
                    any of the materials on Equity Rides application are accurate, complete, or current. Build
                    America Center may make changes to the materials contained on Equity Rides application at
                    any time without notice. Build America Center does not, however, make any commitment to
                    update the materials.
                </p>

                <h3>6. Links</h3>
                <p>
                    Build America Center has not reviewed all of the sites linked to Equity Rides application and
                    is not responsible for the contents of any such linked site. The inclusion of any link does
                    not imply endorsement by Build America Center of the site. Use of any such linked
                    Website/Mobile application is at the user's own risk.
                </p>

                <h3>7. Site Terms of Use Modifications</h3>
                <p>
                    Build America Center may revise these terms of use for the Equity Rides application at any
                    time without notice. By using the Equity Rides application you are agreeing to be bound by
                    the then current version of these Terms and Conditions of Use.
                </p>

                <h3>8. Governing Law</h3>
                <p>
                    Any claim relating to Build America Center's Equity Rides application shall be governed by the
                    laws of the State of Maryland without regard to its conflict of law provisions.
                </p>

                <p>
                    General Terms and Conditions are applicable to use of the Equity Rides application.
                </p>

                <p>
                    We are committed to conducting our business in accordance with these principles in order to
                    ensure that the confidentiality of personal information is protected and maintained.
                </p>
            </div>


        </div>
    );
};

export default Terms;
